import { Link } from 'react-router-dom';
import { PathRoute } from '../../constants/pathRoute/Route';

export default function ConfirmEmailBar() {
  return (
    <>
      <div
        className={
          'tw-flex tw-h-[30px] tw-w-full tw-items-center tw-justify-center tw-bg-nau-green-light tw-text-white'
        }>
        <Link to={PathRoute.VERIFY_EMAIL}>Potwierdź swój adres email</Link>
      </div>
    </>
  );
}
