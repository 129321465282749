export enum Benefit {
  CircleK = 1,
  Loan = 2,
  DiscountCode = 3,
  WalletNau = 4,
  Cashback = 5,
  CinemaVoucher = 6,
  SportCard = 7,
  NauMobile = 8,
}
