import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import MyButton from '../../../components/Buttons/MyButton/MyButton';
import { PhoneSubscriptionLead, StepType } from '../../../types/phoneSubscription';
import { usePhoneSubscriptionLeadStore } from '../../../store/PhoneSubscription/phoneSubscriptionStore';
import agent from '../../../api/agent';
import MyTextInput from '../../../components/FormControls/MyTextInput/MyTextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import StepIndicator from '../../../components/Steps/StepIndicator';
import MyCheckbox from '../../../components/FormControls/MyCheckbox/MyCheckbox';
import * as Yup from 'yup';

interface IInstallmentsFormOneProps {
  afterSave?: () => void;
}
const validationSchema = Yup.object().shape({
  residentialAddress: Yup.string().nullable().required('Pole jest wymagane'),
  residentialPostalCode: Yup.string().nullable().required('Pole jest wymagane'),
  residentialCity: Yup.string().nullable().required('Pole jest wymagane'),
  correspondenceAddress: Yup.string().nullable().required('Pole jest wymagane'),
  correspondencePostalCode: Yup.string().nullable().required('Pole jest wymagane'),
  correspondenceCity: Yup.string().nullable().required('Pole jest wymagane'),
});
export const InstallmentsFormThree = (props: IInstallmentsFormOneProps) => {
  const { lead, setStep } = usePhoneSubscriptionLeadStore();

  return (
    <div className='tw-w-full tw-rounded-b-lg tw-bg-white tw-px-2 tw-py-6 tw-shadow-md sm:tw-px-6'>
      <StepIndicator currentStep={3} totalSteps={3} />
      <div className={'tw-my-4 tw-w-fit tw-cursor-pointer'} onClick={() => setStep(StepType.LoanYourOfferTwo)}>
        <FontAwesomeIcon color='#626d80' icon={faCaretLeft} size='2x' />
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={lead}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setFieldValue }: FormikHelpers<PhoneSubscriptionLead>) => {
          return agent.NauMobile.updateLeadFormModel({
            ...values,
            currentStepId: StepType.LoanYourOfferThree,
          })
            .then(() => {
              props.afterSave?.();
            })
            .finally(() => setSubmitting(false));
        }}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className='tw-w-full '>
            <div className='tw-mb-4 tw-mt-6  tw-text-lg tw-font-semibold tw-text-nau-sea-green'>Adres zamieszkania</div>
            <div className='tw-flex tw-flex-col tw-gap-4'>
              <MyTextInput name='residentialAddress' placeholder='Ulica nr domu/nr mieszkania' />
              <div className={'tw-flex tw-gap-4'}>
                <MyTextInput
                  mask={'99-999'}
                  name='residentialPostalCode'
                  placeholder='Kod pocztowy'
                  wrapperClassName={'tw-w-[140px]'}
                />
                <MyTextInput name='residentialCity' placeholder='Miasto' wrapperClassName={'tw-flex-1'} />
              </div>
            </div>

            <div className='tw-mb-4 tw-mt-6  tw-text-lg tw-font-semibold tw-text-nau-sea-green'>
              Adres korespondencyjny
            </div>
            <div className='tw-mb-4'>
              <MyCheckbox
                label='Taki jak adres zamieszkania'
                name='sameAsResidential'
                value={values.sameAsResidential}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  setFieldValue('sameAsResidential', isChecked);
                  if (isChecked) {
                    setFieldValue('correspondenceAddress', values.residentialAddress);
                    setFieldValue('correspondencePostalCode', values.residentialPostalCode);
                    setFieldValue('correspondenceCity', values.residentialCity);
                  }
                }}
              />
            </div>

            {!values.sameAsResidential && (
              <div className='tw-flex tw-flex-col tw-gap-4'>
                <MyTextInput name='correspondenceAddress' placeholder='Ulica nr domu/nr mieszkania' />
                <div className={'tw-flex tw-gap-4'}>
                  <MyTextInput
                    mask={'99-999'}
                    name='correspondencePostalCode'
                    placeholder='Kod pocztowy'
                    wrapperClassName={'tw-w-[140px]'}
                  />
                  <MyTextInput name='correspondenceCity' placeholder='Miasto' wrapperClassName={'tw-flex-1'} />
                </div>
              </div>
            )}

            <div className='tw-mt-8'>
              <MyButton
                className='tw-w-full'
                disabled={isSubmitting}
                isLoading={isSubmitting}
                type='submit'
                variant={'greenDark'}>
                Dalej
              </MyButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
