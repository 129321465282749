import React from 'react';

export const OptionalApproval = (label: string) => {
  return (
    <div className={'tw-flex tw-items-start'}>
      <div className={'tw-min-w-[15px] tw-pr-[5px] tw-text-lg tw-font-bold'}></div>
      <div dangerouslySetInnerHTML={{ __html: label.replaceAll('\n', '<br/>') }}></div>
    </div>
  );
};
