import React, { useEffect, useState } from 'react';
import { useStore } from '../../store/store';
import { useSwrAgent } from '../../api/useSwrAgent';
import { Form, Formik, FormikHelpers } from 'formik';
import MyButton from '../../components/Buttons/MyButton/MyButton';
import {
  ContractModel,
  ContractPhoneSubscriptionDurationOptions,
  CurrentPhoneSubscriptionContractTypeOptions,
  PhoneModel,
  PhoneOfferType,
  PhoneSubscriptionLead,
  PhoneSubscriptionLeadType,
  PhoneSubscriptionLeadTypeOptions,
  ProviderType,
  StepType,
} from '../../types/phoneSubscription';
import { Image } from 'react-bootstrap';
import * as Yup from 'yup';
import { usePhoneSubscriptionLeadStore } from '../../store/PhoneSubscription/phoneSubscriptionStore';
import agent, { requests } from '../../api/agent';
import LoadingComponent from '../../components/Loadings/LoadingComponent/LoadingComponent';
import { toast } from 'react-toastify';
import MyCircleCheckIcon from '../../components/Icons/MyCircleCheckIcon';
import FileHelper from '../../helpers/FileHelper';
import { PathRoute } from '../../constants/pathRoute/Route';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const validationSchema = Yup.object().shape({
  userId: Yup.number().required('Pole "Identyfikator użytkownika" jest wymagane.'),
  offerTypeId: Yup.number().when('selectedPhoneId', {
    is: 0,
    then: Yup.number().nullable(),
    otherwise: Yup.number().nullable().required('Pole "Typ oferty" jest wymagane.'),
  }),
  applicationTypeId: Yup.number().when('offerTypeId', {
    is: PhoneOfferType.SubscriptionAndPhone,
    then: Yup.number()
      .nullable()
      .required('Pole "Typ wniosku" jest wymagane.')
      .min(1, 'Pole "Typ wniosku" jest wymagane.'),
    otherwise: Yup.number().nullable(),
  }),
  statusId: Yup.number().required('Pole "Status" jest wymagane.'),
  currentStepId: Yup.number().required('Pole "Obecny krok" jest wymagane.'),
  selectedPhoneId: Yup.number().nullable(),
  selectedSubscriptionId: Yup.number().nullable(),
  firstName: Yup.string()
    .nullable()
    .required('Pole "Imię" jest wymagane.')
    .matches(/^[a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ\s-]+$/, 'Pole "Imię" może zawierać tylko litery.'),
  lastName: Yup.string()
    .nullable()

    .required('Pole "Nazwisko" jest wymagane.')
    .matches(/^[a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ\s-]+$/, 'Pole "Nazwisko" może zawierać tylko litery.'),
  contractPhoneNumber: Yup.string()
    .nullable()
    .matches(/^\d{9}$/, 'Pole "Telefon na umowie" musi zawierać dokładnie 9 cyfr.'),
  contractEmail: Yup.string().nullable().email('Pole "Email na umowie" musi być poprawnym adresem email.'),
});

const otherPhoneModel: PhoneModel = {
  id: 0,
  modelName: 'Interesuje mnie inny model',
};

interface SubscriptionFormProps {
  afterSave?: () => void;
}

export const SubscriptionForm = (props: SubscriptionFormProps) => {
  const { data: phoneModels, isLoading: arePhoneModelsLoading } = useSwrAgent().NauMobile.GetAvailablePhonesModel();
  const { lead, updateLead, setStep } = usePhoneSubscriptionLeadStore();
  const selectedPhone = phoneModels?.find((phone) => phone.id === lead.selectedPhoneId);
  const phoneModelsToUse = [...(phoneModels ?? []), otherPhoneModel];
  const [subscriptionOptions, setSubscriptionOptions] = useState<ContractModel[]>();
  const getAvailableProviderContracts = (providerTypeId: number) => {
    agent.NauMobile.getAvailableProviderContracts(providerTypeId).then((response) => setSubscriptionOptions(response));
  };

  useEffect(() => {
    if (lead.providerTypeId) {
      getAvailableProviderContracts(lead.providerTypeId);
    }
  }, [lead]);
  return (
    <div className='tw-w-full tw-rounded-b-lg tw-bg-white  tw-px-2 tw-py-6 tw-shadow-md sm:tw-px-6 '>
      <LoadingComponent content='Ładowanie...' visible={arePhoneModelsLoading} />

      <Formik
        enableReinitialize={true}
        initialValues={lead}
        onSubmit={async (values, { setSubmitting, setFieldValue }: FormikHelpers<PhoneSubscriptionLead>) => {
          try {
            await validationSchema.validate(values);
          } catch (error: any) {
            toast.error(error.message as string);
            return;
          }
          if (values.offerTypeId === null || values.offerTypeId === undefined || values.offerTypeId === 0) {
            toast.info('Proszę wybrać opcję oferty');
            return;
          }
          if (
            (values.offerTypeId === PhoneOfferType.SubscriptionAndPhone ||
              values.offerTypeId == PhoneOfferType.Subscription) &&
            (values.selectedSubscriptionId === null ||
              values.selectedSubscriptionId === undefined ||
              values.selectedSubscriptionId === 0)
          ) {
            toast.info('Proszę wybrać usługę');
            return;
          }
          agent.NauMobile.updateLeadFormModel({
            ...values,
            currentStepId: StepType.SelectProvider,
          }).then(() => {
            props.afterSave?.();
          });
        }}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className='tw-w-full'>
            <div className={'tw-w-full tw-px-2 tw-pt-6 sm:tw-px-6 '}>
              <div className={'tw-mb-4 tw-w-fit tw-cursor-pointer '} onClick={() => setStep(StepType.SelectDiscounts)}>
                <FontAwesomeIcon color='#626d80' icon={faCaretLeft} size='2x' />
              </div>
              {lead.selectedPhoneId != 0 && (
                <div>
                  <div className='tw-mb-4 tw-mt-4 tw-text-center tw-text-xl tw-font-semibold tw-text-nau-green-dark'>
                    Czy jesteś zainteresowany usługą SIM NAU?
                  </div>
                  <div className={'tw-flex tw-justify-center tw-gap-4'}>
                    <div className={'tw-relative'}>
                      {lead.offerTypeId == PhoneOfferType.SubscriptionAndPhone && (
                        <div
                          className={
                            'tw-absolute tw-right-[15px] tw-top-[12px] tw-z-30 tw-rounded-full tw-bg-nau-green-light tw-p-0.5 tw-shadow'
                          }>
                          <MyCircleCheckIcon className={'tw-h-5 tw-w-5   tw-text-white'} />
                        </div>
                      )}
                      <Image
                        className={'tw-cursor-pointer tw-bg-white'}
                        onClick={() => {
                          updateLead({
                            offerTypeId: PhoneOfferType.SubscriptionAndPhone,
                            providerTypeId: ProviderType.SimNau,
                            applicationTypeId: PhoneSubscriptionLeadType.BuyNewNumber,
                          });
                          getAvailableProviderContracts(ProviderType.SimNau);
                        }}
                        src='/assets/nauMobile/subscriptionSimNauIcon.svg'
                      />
                    </div>
                    <div className={'tw-relative'}>
                      {lead.offerTypeId == PhoneOfferType.Phone && (
                        <div
                          className={
                            'tw-absolute tw-right-[15px] tw-top-[12px] tw-z-30 tw-rounded-full tw-bg-nau-green-light tw-p-0.5 tw-shadow'
                          }>
                          <MyCircleCheckIcon className={'tw-h-5 tw-w-5 tw-text-white'} />
                        </div>
                      )}
                      <Image
                        className={'tw-cursor-pointer tw-bg-white'}
                        src='/assets/nauMobile/phoneWithoutSubscriptionIcon.svg'
                        onClick={() => {
                          updateLead({
                            offerTypeId: PhoneOfferType.Phone,
                            selectedSubscriptionId: undefined,
                            applicationTypeId: undefined,
                            providerTypeId: undefined,
                            identityDocumentScanFileId: undefined,
                            currentOperatorContractDuration: undefined,
                            currentOperatorContractType: undefined,
                            currentOperator: undefined,
                            numberToTransfer: undefined,
                          });
                          setSubscriptionOptions(undefined);
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={`tw-mt-6 tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center ${lead.offerTypeId == PhoneOfferType.SubscriptionAndPhone ? '' : 'tw-hidden'}`}>
                    <div
                      className={
                        'tw-m-8 tw-flex tw-w-fit tw-max-w-full tw-items-center tw-justify-center tw-gap-4 tw-overflow-x-auto'
                      }>
                      {PhoneSubscriptionLeadTypeOptions.map((option) => (
                        <div key={option.value}>
                          <div
                            className={`btn ${lead.applicationTypeId == option.value ? 'btn-primary' : 'btn-white'} tw-cursor-pointer`}
                            onClick={() => {
                              updateLead({ applicationTypeId: option.value });
                            }}>
                            {option.label}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className='tw-mb-4 tw-mt-4 tw-text-center tw-text-xl tw-font-semibold tw-text-nau-green-dark'>
                      Wybrany przez Ciebie pakiet*
                    </div>
                    <div className='btn btn-primary tw-pointer-events-none'>Smartfon + usługa Sim NAU</div>
                    <div
                      className={
                        'tw-m-8 tw-flex tw-w-fit tw-max-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-overflow-x-auto'
                      }>
                      {subscriptionOptions &&
                        subscriptionOptions.map((option, idx) => (
                          <div
                            key={option.id}
                            className={'tw-flex tw-w-fit tw-max-w-full tw-flex-col tw-items-center tw-justify-center'}
                            style={
                              {
                                height: '280px',
                              } as React.CSSProperties
                            }>
                            {lead.selectedSubscriptionId == option.id ? (
                              <Image
                                className={`tw-cursor-pointer tw-bg-white`}
                                style={
                                  {
                                    width: '295px',
                                  } as React.CSSProperties
                                }
                                src={`/assets/nauMobile/simNau${idx + 1}_tile_active.png`}
                                onClick={() => updateLead({ selectedSubscriptionId: option.id })}
                              />
                            ) : (
                              <Image
                                className={`tw-cursor-pointer tw-bg-white`}
                                style={
                                  {
                                    width: '250px',
                                  } as React.CSSProperties
                                }
                                src={`/assets/nauMobile/simNau${idx + 1}_tile.svg`}
                                onClick={() => updateLead({ selectedSubscriptionId: option.id })}
                              />
                            )}
                          </div>
                        ))}
                    </div>
                    <div>
                      *Opłata za usługę pobierana jest z góry za 12 miesięcy.
                      <br /> *Jednorazowo do KAŻDEJ karty naliczana jest opłata:
                      <br /> - operacyjna 20 zł
                      <br /> - aktywacyjna 1,23 zł
                    </div>
                    <div
                      className={'tw-mt-6 tw-flex tw-w-full tw-cursor-pointer tw-justify-end tw-gap-4'}
                      onClick={() =>
                        FileHelper.downloadFile(
                          PathRoute.PHONE_SUBSCRIPTION_PROMO_TERMS_PDF,
                          'Regulamin-Promocji-NAU-Laczy-Oswiate.pdf',
                        )
                      }>
                      <div className={'tw-text-nau-green-2'}>Regulamin Promocji {'>>'}</div>
                    </div>
                  </div>
                </div>
              )}

              <div className='tw-mt-8 tw-flex tw-flex-col tw-gap-4'>
                <MyButton
                  className='tw-w-full'
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  type='submit'
                  variant={'greenDark'}>
                  Dalej
                </MyButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
