import React from 'react';
import { ContractModel, PhoneModel, StepType } from '../../types/phoneSubscription';
import { usePhoneSubscriptionLeadStore } from '../../store/PhoneSubscription/phoneSubscriptionStore';

interface IPhoneSubscriptionDescriptionProps {
  subscription: ContractModel;
}
export const PhoneSubscriptionDescription = (props: IPhoneSubscriptionDescriptionProps) => {
  const { setStep } = usePhoneSubscriptionLeadStore();

  const priceDisplay = (price: string) => {
    // Extract the numeric part (e.g., "8.72") and split it into main and fractional parts
    const [main, fraction] = price.match(/\d+\.\d+/)?.[0].split('.') || ['0', '00'];
    // Extract the remaining part (e.g., "zł/mies.")
    const suffix = price.replace(/\d+\.\d+\s*/, '');
    return (
      <div className='tw-flex tw-items-start tw-text-[40px]'>
        <span className='tw-font-semi-bold tw-text-[72px] tw-leading-none '>{main}</span>
        <span className='tw-flex tw-flex-col tw-items-start tw-pt-[2px] tw-text-[18px]'>
          <span className='tw-font-semi-bold tw-text-[36px] tw-leading-none '>{fraction}</span>
          <span className='tw-ml-3 tw-text-[16px] tw-font-normal tw-leading-normal'>{suffix}</span>
        </span>
      </div>
    );
  };
  return (
    <>
      <div className='tw-mb-4 tw-mt-8 tw-text-center tw-text-xl tw-font-semibold tw-text-nau-green-dark'>
        {props.subscription.name}
      </div>
      <div>
        <div className={'tw-text-nau-sea-green'}>
          {props.subscription.calls && (
            <div className='tw-flex'>
              <div className='tw-mr-2'>Rozmowy</div>
              <strong>{props.subscription.calls}</strong>
            </div>
          )}
          {props.subscription.smsMms && (
            <div className='tw-flex'>
              <div className='tw-mr-2'>SMS / MMS </div>
              <strong>{props.subscription.smsMms}</strong>
            </div>
          )}
          {props.subscription.internetDescription && (
            <div>
              <div>{props.subscription.internetDescription}</div>
            </div>
          )}
          <br />
          {props.subscription.generalDescription && (
            <div>
              <div>{props.subscription.generalDescription}</div>
            </div>
          )}
          <br />
          {props.subscription.monthlyPrice && (
            <div>
              <div className='tw-flex tw-items-center tw-justify-between'>
                <div>Cena usługi</div>
                <div>{priceDisplay(props.subscription.monthlyPrice.toFixed(2) + 'zł/mies.')}</div>
              </div>
              <div className='tw-mt-2 tw-flex tw-flex-col tw-items-end'>
                <div>
                  <strong
                    className={'tw-mb-4 tw-w-fit tw-cursor-pointer '}
                    onClick={() => setStep(StepType.SelectProvider)}>
                    Zmień ofertę
                  </strong>
                </div>
              </div>
              <div className='tw-mt-4 tw-flex tw-justify-center'>
                <strong>*Opłata za usługę pobierana jest z góry za 12 miesięcy.</strong>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
