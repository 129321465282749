import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import MyButton from '../../../components/Buttons/MyButton/MyButton';
import { PhoneOfferType, PhoneSubscriptionLead, StepType } from '../../../types/phoneSubscription';
import { usePhoneSubscriptionLeadStore } from '../../../store/PhoneSubscription/phoneSubscriptionStore';
import agent from '../../../api/agent';
import MyTextInput from '../../../components/FormControls/MyTextInput/MyTextInput';
import * as Yup from 'yup';

import {
  approvalForPhoneSubscriptionContractSecond1,
  approvalForPhoneSubscriptionContractSecond2,
} from '../phoneSubscriptionContants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import MyCheckbox from '../../../components/FormControls/MyCheckbox/MyCheckbox';
import StepIndicator from '../../../components/Steps/StepIndicator';
import { OptionalApproval } from '../Approvals/OptionalApproval';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().nullable().required('Pole jest wymagane'),
  lastName: Yup.string().nullable().required('Pole jest wymagane'),
  contractPhoneNumber: Yup.string()
    .nullable()
    .matches(/^\d{9}$/, 'Pole "Telefon na umowie" musi zawierać dokładnie 9 cyfr.'),
  contractEmail: Yup.string().nullable().email('Pole "Email na umowie" musi być poprawnym adresem email.'),
  pesel: Yup.string().nullable().required('Pole jest wymagane'),
});

interface IInstallmentsFormOneProps {
  afterSave?: () => void;
}

export const CashFormOne = (props: IInstallmentsFormOneProps) => {
  const { lead, setStep } = usePhoneSubscriptionLeadStore();

  return (
    <div className='tw-w-full tw-rounded-b-lg tw-bg-white tw-px-2 tw-py-6 tw-shadow-md sm:tw-px-6'>
      <StepIndicator currentStep={1} totalSteps={2} />
      <div className={'tw-my-4 tw-w-fit tw-cursor-pointer'} onClick={() => setStep(StepType.BuyPhone)}>
        <FontAwesomeIcon color='#626d80' icon={faCaretLeft} size='2x' />
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={lead}
        onSubmit={async (values, { setSubmitting, setFieldValue }: FormikHelpers<PhoneSubscriptionLead>) => {
          const optionalApprovals: string[] = [];
          if (values.approvalForContractSecond1) {
            optionalApprovals.push(`1. ${approvalForPhoneSubscriptionContractSecond1}`);
          }
          if (values.approvalForContractSecond2) {
            optionalApprovals.push(`2. ${approvalForPhoneSubscriptionContractSecond2}`);
          }
          const concatenatedOptionalApprovals = optionalApprovals.map((approval) => `${approval}`).join('<br />');
          return agent.NauMobile.updateLeadFormModel({
            ...values,
            currentStepId: StepType.CashYourOfferOne,
            approvals: concatenatedOptionalApprovals,
          })
            .then(() => {
              props.afterSave?.();
            })
            .finally(() => setSubmitting(false));
        }}
        validationSchema={validationSchema}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className='tw-w-full'>
            <div className='tw-mb-4 tw-mt-6  tw-text-lg tw-font-semibold tw-text-nau-sea-green'>Dane osobowe</div>
            <div className='tw-flex tw-flex-col tw-gap-4'>
              <MyTextInput disabled={true} name='firstName' placeholder='Imię' />
              <MyTextInput disabled={true} name='lastName' placeholder='Nazwisko' />
              <MyTextInput name='contractPhoneNumber' placeholder='Nr telefonu' />
              <MyTextInput disabled={true} name='contractEmail' placeholder='Adres mailowy' />
              <MyTextInput disabled={true} name='pesel' placeholder='PESEL' />
            </div>
            <>
              <div className={'tw-text-nau-tw-text-nau-sea-green-green tw-pb-4 tw-pt-6 tw-text-center'}>
                Zgody są wymagane do ukończenia transakcji
              </div>

              <MyCheckbox
                checked={
                  values.approvalForContractSecond1 &&
                  values.approvalForContractSecond2 &&
                  values.approvalForContractSecond3
                }
                label={<div>Zaznacz wszystkie zgody</div>}
                labelClassName={'tw-text-nau-gray'}
                name={`allApprovals`}
                onChange={(event) => {
                  const allChecked = (event.target as HTMLInputElement).checked;
                  setFieldValue('approvalForContractSecond1', allChecked);
                  setFieldValue('approvalForContractSecond2', allChecked);
                  setFieldValue('approvalForContractSecond3', allChecked);
                }}
                variant={'big'}
              />
              <div className={'tw-mt-4 tw-flex tw-flex-col tw-gap-4'}>
                <div>
                  <MyCheckbox
                    label={OptionalApproval(approvalForPhoneSubscriptionContractSecond1)}
                    name={`approvalForContractSecond1`}
                    type='checkbox'
                    align='start'
                    variant={'big'}
                  />
                </div>
                <div>
                  <MyCheckbox
                    label={OptionalApproval(approvalForPhoneSubscriptionContractSecond2)}
                    name={`approvalForContractSecond2`}
                    type='checkbox'
                    align='start'
                    variant={'big'}
                  />
                </div>
              </div>
            </>
            <div className='tw-mt-8'>
              <MyButton
                className='tw-w-full'
                disabled={isSubmitting}
                isLoading={isSubmitting}
                type='submit'
                variant={'greenDark'}>
                Dalej
              </MyButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
