import React, { useEffect, useState } from 'react';
import CustomerDashboard from '../CustomerDashboard/CustomerDashboard';
import SubpageHeader from '../../components/SubpageHeader/SubpageHeader';
import PlaneIcon from '../../components/Icons/PlaneIcon';
import MyButton from '../../components/Buttons/MyButton/MyButton';
import { Form, Formik } from 'formik';
import { initialValues } from '.';
import { TripInsuranceBasicFormValues } from '../../types/tripInsurance';
import RegexHelpers from '../../helpers/RegexHelpers';
import * as Yup from 'yup';
import MyTextInput from '../../components/FormControls/MyTextInput/MyTextInput';
import MaskHelpers from '../../helpers/MaskHelpers';
import { toast } from 'react-toastify';
import TripInsuranceForm from '../../components/TripInsurance/TripInsuranceForm/TripInsuranceForm';
import { useStore } from '../../store/store';
import { CallBookLeadFormValues, LeadCampaignType } from '../../types/leads';
import agent from '../../api/agent';
import UserPersonalDataLayout from '../../components/UserPersonalData/UserPersonalDataLayout';

const TripInsurancePage = () => {
  const { leadStore, userStore } = useStore();
  const [isFormView, setIsFormView] = useState<boolean>(false);
  const [isSent, setIsSent] = useState<boolean>(false);
  const [step, setStep] = useState<number>(2);
  const [formValues, setFormValues] = useState<TripInsuranceBasicFormValues>(initialValues);
  const [isCallBookSubmitting, setIsCallBookSubmitting] = useState<boolean>(false);

  useEffect(() => {
    const user = userStore.userData;
    formValues.firstName = user?.firstName ?? '';
    formValues.lastName = user?.lastName ?? '';
    formValues.phone = user?.phone ?? '';
  }, []);

  const sendCallBookLead = async () => {
    setIsCallBookSubmitting(true);
    try {
      if (!formValues.firstName && !formValues.lastName && !formValues.phone) {
        toast.info('Proszę podać dane');
        return;
      }
      try {
        await validationSchema.validate(formValues);
      } catch (error: any) {
        toast.error(error.message as string);
        return;
      }
      const values: CallBookLeadFormValues = {
        ...formValues,
        campaignType: LeadCampaignType.Trip,
        campaignTypeHumanName: 'Ubezpieczenie turystyczne',
        contactApproval: true,
      };
      await agent.Lead.sendCallBookLead(values).then(() => {
        setIsSent(true);
      });
    } catch (error) {
      console.error('Error sending request', error);
    } finally {
      setIsCallBookSubmitting(false);
    }
  };

  const handleChangeValue = (name: string, value: any) => {
    if (value === 'true' || value === 'false') {
      const booleanValue = value === 'true' ? true : value === 'false' ? false : value;
      setFormValues({ ...formValues, [name]: booleanValue });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Pole jest wymagane'),
    lastName: Yup.string().required('Pole jest wymagane'),
    phone: Yup.string()
      .matches(RegexHelpers.phoneRegExp, 'Nieprawidłowy numer telefonu')
      .required('Pole jest wymagane'),
  });
  return (
    <CustomerDashboard>
      <UserPersonalDataLayout
        wrapperClassName={'tw-mx-auto tw-max-w-2xl tw-bg-white tw-px-2 tw-py-6 sm:tw-px-6 tw-rounded-lg'}>
        <div className='tw-mx-auto md:tw-w-1/2'>
          <SubpageHeader
            headerClassName={'tw-text-3xl'}
            iconComponent={<PlaneIcon className={'sm:tw-mr-4'} />}
            iconHeight={35}
            title={'Ubezpieczenie turystyczne'}
          />
          <div className='tw-w-full tw-rounded-b-lg tw-bg-white tw-p-6 tw-text-nau-green-dark tw-shadow-md'>
            {isSent ? (
              <TripInsuranceForm values={formValues} isSent={true} />
            ) : (
              <>
                {isFormView ? (
                  <TripInsuranceForm values={formValues} isSent={false} />
                ) : (
                  <>
                    <div>
                      <Formik
                        enableReinitialize
                        initialValues={formValues}
                        onSubmit={async (values, { setErrors, setSubmitting }) => {
                          try {
                            setIsFormView(true);
                          } catch (error) {
                            console.error('Error sending request', error);
                          } finally {
                            setSubmitting(false);
                          }
                        }}
                        validationSchema={validationSchema}>
                        {({ isSubmitting, errors }) => (
                          <Form className='tw-w-full '>
                            {step === 1 && (
                              <>
                                <h2 className='tw-text-xl tw-font-semibold '>
                                  Ubezpieczenie turystyczne - ochrona w czasie podróży
                                </h2>
                                <p className='tw-mt-8  tw-text-base'>
                                  Zadbaj o swoje bezpieczeństwo w trakcie każdego wyjazdu za granicę.
                                </p>
                                <div className='tw-mt-8 tw-flex tw-flex-col tw-gap-1 tw-text-base tw-font-normal'>
                                  <div>● Wybór najkorzystniejszej oferty spośród dostępnych na rynku,</div>
                                  <div>● współpracujemy z 20 Towarzystwami Ubezpieczeniowymi</div>
                                  <div>● 100% online</div>
                                </div>
                                <div className='tw-mt-8 tw-flex tw-flex-col tw-gap-1 tw-text-xs tw-font-normal'>
                                  <b>Dla klientów posiadających grupowe ubezpieczenie na życie</b> (zawarte za
                                  pośrednictwem NAU S.A. w placówce oświatowej){' '}
                                  <b>
                                    przysługuje możliwość skorzystania z programu ZWROTU SKŁADKI - promocja wynosi
                                    maksymalnie 25% od wyliczonej składki
                                  </b>{' '}
                                  (w granicach zgromadzonych w skarbonce środków).
                                </div>
                                <div className='tw-mb-4'>
                                  <MyButton
                                    className={'tw-mt-8 tw-w-full'}
                                    onClick={() => setStep(2)}
                                    type='button'
                                    variant={'greenDark'}>
                                    Dalej
                                  </MyButton>
                                </div>
                              </>
                            )}
                            {step === 2 && (
                              <>
                                <div
                                  className={
                                    'tw-my-8 tw-text-center tw-text-xl tw-font-semibold tw-text-nau-green-dark'
                                  }>
                                  Chcesz, aby agent przygotował dla Ciebie ofertę ubezpieczenia turystycznego?
                                </div>
                                <div className='tw-mb-4 tw-flex tw-flex-col tw-gap-2'>
                                  <div className='tw-flex'>
                                    <div className='tw-w-1/2 tw-pr-1.5'></div>
                                    <div className='tw-w-1/2 tw-pl-1.5'></div>
                                  </div>
                                  <MyTextInput
                                    placeholder='Imię'
                                    name='firstName'
                                    onChange={(e) => handleChangeValue('firstName', e.target.value)}
                                  />
                                  <MyTextInput
                                    placeholder='Nazwisko'
                                    name='lastName'
                                    onChange={(e) => handleChangeValue('lastName', e.target.value)}
                                  />
                                  <MyTextInput
                                    placeholder='Numer telefonu'
                                    name='phone'
                                    onChange={(e) => handleChangeValue('phone', e.target.value)}
                                    mask={MaskHelpers.phone}
                                  />
                                </div>
                                <div className='tw-mb-4'>
                                  <MyButton
                                    className={'tw-mt-4 tw-w-full'}
                                    disabled={isSubmitting}
                                    isLoading={isSubmitting}
                                    loadingText={'Wysyłanie...'}
                                    type='submit'
                                    variant={'primary'}>
                                    Wypełnij formularz
                                  </MyButton>
                                  <div className='tw-mt-4 tw-block tw-h-px tw-w-full tw-bg-gray-300'></div>
                                  <p className='tw-flex tw-justify-center'>albo</p>
                                  <MyButton
                                    className={'tw-mt-8 tw-w-full'}
                                    onClick={() => sendCallBookLead()}
                                    type='button'
                                    disabled={isCallBookSubmitting}
                                    isLoading={isCallBookSubmitting}
                                    loadingText={'Wysyłanie...'}
                                    variant={'greenDark'}>
                                    Zamów rozmowę
                                  </MyButton>
                                </div>
                              </>
                            )}
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </UserPersonalDataLayout>
    </CustomerDashboard>
  );
};

export default TripInsurancePage;
