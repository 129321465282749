import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ReactNode, useEffect } from 'react';
import { useStore } from '../../store/store';
import Header from '../../components/Header/Header';
import AdminMainSideBar from '../../components/AdminMainMenu/AdminMainSideBar';
import { Redirect } from 'react-router-dom';
import { PathRoute } from '../../constants/pathRoute/Route';
import ProfileMenuSideBar from '../../components/AdminProfileMenu/ProfileMenuSideBar';
import AdminAuthorization from '../../components/AdminAuthorization/AdminAuthorization';
import useHeaderHeight from '../../hooks/useHeaderHeight';

interface AdminDashboardProps {
  children: ReactNode;
}
export default observer(({ children }: AdminDashboardProps) => {
  const { userStore, commonStore } = useStore();
  const headerHeight = useHeaderHeight();

  useEffect(() => {
    if (commonStore.authGuid != null) {
      userStore.checkInstitutionAdminAuthorization();
    }
  }, [userStore]);
  return (
    <>
      {!userStore.isLoggedInstAdmin ? (
        <Redirect to={PathRoute.PUSTY} />
      ) : (
        <div>
          {userStore.isAuthorizedToInst && <AdminMainSideBar />}
          <ProfileMenuSideBar />
          <Header />
          <div style={{ height: `${headerHeight}px` }}>{/* Empty div for header height */}</div>

          {!userStore.isAuthorizedToInst ? <AdminAuthorization /> : <main>{children}</main>}
        </div>
      )}
    </>
  );
});
