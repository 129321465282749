import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import CustomerDashboard from '../CustomerDashboard/CustomerDashboard';
import News from '../News/News';
import LoadingComponent from '../../components/Loadings/LoadingComponent/LoadingComponent';
import NewBenefitTile from './NewBenefitTile';
import { PathRoute } from '../../constants/pathRoute/Route';
import styles from './styles.module.scss';
import useSWR from 'swr';
import { UserAvailableBenefits } from '../../types/user';
import { Benefit } from '../../enums/benefits.enums';
import getUrls from '../../api/getUrls';
import { useStore } from '../../store/store';
import HouseIcon from '../../components/Icons/HouseIcon';
import PlaneIcon from '../../components/Icons/PlaneIcon';
import PhoneIcon from '../../components/Icons/PhoneIcon';
import CarIcon from '../../components/Icons/CarIcon';
import { useSwrAgent } from '../../api/useSwrAgent';
import { UserPromotionE } from '../../enums/userPromotion.enums';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default observer(() => {
  const { ArticlesStore, userStore, circleKStore } = useStore();
  const { data: userAvailableBenefits, isLoading: userAvailableBenefitsLoading } = useSWR<UserAvailableBenefits[]>(
    getUrls.User.getUserAvailableBenefits,
  );
  const { data: hasInstitutionalCodes, isLoading: hasInstitutionalCodesLoading } = useSWR<boolean>(
    getUrls.DiscountCodes.checkHasInstitutionalDiscountCodesByUser,
  );
  const { data: userMarketingData, isLoading: areMarketingDataLoading } = useSwrAgent().Users.GetUserMarketingData();
  const isComponentLoading = userAvailableBenefitsLoading || hasInstitutionalCodesLoading || areMarketingDataLoading;

  const isCinemaDirectorPromo =
    userMarketingData &&
    userMarketingData.userPromotionIds?.some((id) => id === UserPromotionE.CinemaVouchersSchoolDirectors);

  const isFromNnwLink =
    userMarketingData && userMarketingData.userPromotionIds?.some((id) => id === UserPromotionE.NnwKidsCashback);

  useEffect(() => {
    getAllValues();
  }, []);
  const getAllValues = async () => {
    ArticlesStore.getArticles();
  };
  const isLoanVisible = userAvailableBenefits?.some((b) => {
    return b.benefitTypeId == Benefit.Loan && b.isInstitutional;
  });

  return (
    <CustomerDashboard>
      <LoadingComponent visible={isComponentLoading} />
      <div className={styles.mainPageContainer}>
        <div className={'tw-flex tw-w-full tw-flex-col tw-gap-8 tw-rounded-lg tw-bg-white tw-p-4'}>
          <div>
            <div className={'tw-mb-4'}>
              <Link to={PathRoute.PHONE_SUBSCRIPTION_PROMO}>
                <div className={'tw-px-1 tw-text-lg tw-font-semibold tw-text-nau-sea-green'}>
                  <div>NAU łączy oświatę</div>
                  <div>Oferta specjalna na telefon dla Dyrektorów placówek oświatowych ubezpieczonych przez NAU</div>
                </div>

                <Image src={'/assets/nauMobilePromo/mainTile.png'} style={{ maxHeight: '300px' }} />
              </Link>
            </div>
            <div className={'tw-mb-2 '}>
              <p className={styles.benefitTextStyles}>Moje Benefity:</p>
            </div>
            <div className='gap-2 tw-grid tw-grid-cols-2 tw-overflow-auto sm:tw-grid-cols-3 lg:tw-grid-cols-4'>
              {!userStore.isDemoUser && (
                <NewBenefitTile
                  iconHeight={30}
                  iconSrc={'/assets/cashbackIcon.svg'}
                  route={PathRoute.CASHBACK}
                  text={'Zwrot składki'}
                  theme={'green'}
                  wrapperClassName={'p-0 tw-w-full tw-flex-none'}
                />
              )}

              {(!isFromNnwLink || userMarketingData?.hasBr) && (
                <>
                  <NewBenefitTile
                    iconHeight={30}
                    iconSrc={'/assets/cinemaVoucher.svg'}
                    route={isCinemaDirectorPromo ? PathRoute.CINEMA_VOUCHER_DIRECTOR_PROMO : PathRoute.CINEMA_VOUCHER}
                    text={'Kupony do kina'}
                    theme={'green'}
                    wrapperClassName={'p-0 tw-w-full tw-flex-none'}
                  />
                  <NewBenefitTile
                    iconComponent={<PhoneIcon className={'tw-h-[30px] tw-text-white'} />}
                    route={PathRoute.PHONE_SUBSCRIPTION}
                    text={'Abonament komórkowy'}
                    theme={'green'}
                    wrapperClassName={'p-0 tw-w-full tw-flex-none'}
                  />

                  {!userStore.isDemoUser && (
                    <NewBenefitTile
                      iconSrc={'/assets/fuelWhite.svg'}
                      route={PathRoute.FUEL_CARDS}
                      text={
                        circleKStore.hasAnyNauProfitCard || circleKStore.circleKCard.length == 0
                          ? 'Karty paliwowe NAU Profit'
                          : 'Karty paliwowe'
                      }
                      theme={'green'}
                      wrapperClassName={'p-0 tw-w-full tw-flex-none'}
                    />
                  )}

                  {isLoanVisible && (
                    <NewBenefitTile
                      iconSrc={'/assets/whiteCash.svg'}
                      route={PathRoute.LOAN}
                      text={'Wypłata od ręki'}
                      theme={'green'}
                      wrapperClassName={'p-0 tw-w-full tw-flex-none'}
                    />
                  )}
                  {!userStore.isDemoUser && (
                    <NewBenefitTile
                      iconSrc={'/assets/bony.svg'}
                      route={PathRoute.DISCOUNT_CODE}
                      text={'E-bony'}
                      theme={'green'}
                      wrapperClassName={'p-0 tw-w-full tw-flex-none'}
                    />
                  )}
                  {hasInstitutionalCodes && (
                    <NewBenefitTile
                      iconSrc={'/assets/bony.svg'}
                      route={PathRoute.INSTITUTIONAL_DISCOUNT_CODE}
                      text={'E-bony od pracodawcy'}
                      theme={'green'}
                      wrapperClassName={'p-0 tw-w-full tw-flex-none'}
                    />
                  )}
                  {!userStore.isDemoUser && (
                    <NewBenefitTile
                      iconHeight={30}
                      iconSrc={'/assets/wallet.svg'}
                      route={PathRoute.WALLET_NAU}
                      text={'Portfel NAU'}
                      theme={'green'}
                      wrapperClassName={'p-0 tw-w-full tw-flex-none'}
                    />
                  )}
                  <NewBenefitTile
                    iconHeight={30}
                    iconSrc={'/assets/13.svg'}
                    route={PathRoute.LOAN_NAU}
                    text={'Pożyczka NAU'}
                    theme={'green'}
                    wrapperClassName={'p-0 tw-w-full tw-flex-none'}
                  />
                  {!userStore.isDemoUser && (
                    <NewBenefitTile
                      iconHeight={35}
                      iconSrc={'/assets/sportMan.svg'}
                      route={PathRoute.SPORT_CARD}
                      text={'Karty sportowe'}
                      theme={'green'}
                      wrapperClassName={'p-0 tw-w-full tw-flex-none'}
                    />
                  )}
                </>
              )}
            </div>
          </div>

          <div>
            <div className={'tw-mb-2'}>
              <p className={styles.benefitTextStyles}>Ubezpieczenia:</p>
            </div>
            <div className='gap-2 tw-grid tw-grid-cols-2 tw-overflow-auto sm:tw-grid-cols-3 lg:tw-grid-cols-4'>
              {!userStore.isDemoUser && (
                <>
                  <NewBenefitTile
                    iconComponent={<CarIcon className={'tw-h-[30px] tw-w-[34px] tw-text-white'} />}
                    route={PathRoute.CAR_INSURANCE}
                    text={'OC / AC Samochód'}
                    theme={'green'}
                    wrapperClassName={'p-0 tw-w-full tw-flex-none'}
                  />
                  <NewBenefitTile
                    iconComponent={<HouseIcon className={'tw-h-[34px] tw-w-[34px] tw-text-white'} />}
                    route={PathRoute.PROPERTY_INSURANCE}
                    text={'Dom / mieszkanie'}
                    theme={'green'}
                    wrapperClassName={'p-0 tw-w-full tw-flex-none'}
                  />
                  <NewBenefitTile
                    iconComponent={<PlaneIcon className={'tw-h-[30px] tw-w-[30px] tw-text-white'} />}
                    route={PathRoute.TRIP_INSURANCE}
                    text={'Podróż'}
                    theme={'green'}
                    wrapperClassName={'p-0 tw-w-full tw-flex-none'}
                  />
                </>
              )}
            </div>
          </div>
          {/* {!userStore.isOpzz && !isFromNnwLink && ( */}
          {/*   <div> */}
          {/*     <div className={'tw-mb-2 '}> */}
          {/*       <p className={styles.benefitTextStyles}>Twoje prezenty</p> */}
          {/*     </div> */}
          {/*     <div className='gap-2 tw-flex tw-overflow-auto sm:tw-grid sm:tw-grid-cols-3 lg:tw-grid-cols-4'> */}
          {/*       <NewBenefitTile */}
          {/*         iconSrc={'/assets/birthdayCodes.svg'} */}
          {/*         route={PathRoute.BIRTHDAY_CODES} */}
          {/*         text={'Urodzinowe prezenty'} */}
          {/*         theme={'orange'} */}
          {/*         wrapperClassName={'p-0 tw-w-full  tw-flex-none'} */}
          {/*       /> */}
          {/*     </div> */}
          {/*   </div> */}
          {/* )} */}
        </div>

        <div>
          <News />
        </div>
      </div>
    </CustomerDashboard>
  );
});
