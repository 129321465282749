export const ZnpRegion = [
  { value: 1, label: 'Okręg Dolnośląski ZNP' },
  { value: 2, label: 'Okręg Kujawsko-Pomorski ZNP' },
  { value: 3, label: 'Okręg Lubelski ZNP' },
  { value: 4, label: 'Okręg Lubuski ZNP' },
  { value: 5, label: 'Okręg Łódzki ZNP' },
  { value: 6, label: 'Okręg Małopolski ZNP' },
  { value: 7, label: 'Okręg Mazowiecki ZNP' },
  { value: 8, label: 'Okręg Opolski ZNP' },
  { value: 9, label: 'Okręg Podkarpacki ZNP' },
  { value: 10, label: 'Okręg Podlaski ZNP' },
  { value: 11, label: 'Okręg Pomorski ZNP' },
  { value: 12, label: 'Okręg Śląski ZNP' },
  { value: 13, label: 'Okręg Świętokrzyski ZNP' },
  { value: 14, label: 'Okręg Warmińsko-Mazurski ZNP' },
  { value: 15, label: 'Okręg Wielkopolski ZNP' },
  { value: 16, label: 'Okręg Zachodniopomorski ZNP' },
];
