import { ErrorMessage, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import MyTextInput from '../../components/FormControls/MyTextInput/MyTextInput';
import MyTextArea from '../../components/FormControls/MyTextArea/MyTextArea';
import { ContactFormValues } from '../../types/contactForm';
import { useStore } from '../../store/store';
import { toast } from 'react-toastify';
import { heading, phoneNumber } from './styles';
import LogoHeader from '../../components/LogoHeader/LogoHeader';

export default observer(() => {
  const { userStore } = useStore();
  const [contactValues, setContactValues] = useState<ContactFormValues>({ email: '', subject: '', content: '' });
  return (
    <div className={''}>
      <div className=' col-md-6 offset-md-3 tw-px-4 tw-py-8 sm:tw-px-6'>
        <Formik
          enableReinitialize
          initialValues={{
            email: contactValues.email,
            subject: contactValues.subject,
            content: contactValues.content,
            error: null,
          }}
          onSubmit={(values, { setErrors, setSubmitting, resetForm }) => {
            userStore
              .sendContactForm(values)
              .then(() => {
                toast.success('Pomyślnie wysłano formularz.');
                setContactValues({ email: '', subject: '', content: '' });
                setSubmitting(false);
                resetForm();
              })
              .catch((error: any) => {
                setErrors({ error });
                setSubmitting(false);
              });
          }} // == {error} shourtcut
          validationSchema={Yup.object({
            email: Yup.string().email('Podaj poprawny adres email').required('Pole jest wymagane'),
            subject: Yup.string().required('Pole jest wymagane'),
            content: Yup.string().required('Pole jest wymagane'),
          })}>
          {({ handleSubmit, isSubmitting, errors, isValid, values }) => (
            <Form autoComplete='off' className='ui form error' onSubmit={handleSubmit}>
              <div
                style={
                  {
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '20px',
                  } as React.CSSProperties
                }>
                <LogoHeader />
              </div>
              <h3 style={{ color: 'teal', textAlign: 'left' }}>Nauczycielska Agencja Ubezpieczeniowa S.A.</h3>
              <br />
              <p>ul. Wybrzeże Kościuszkowskie 31/33</p>
              <p>00-379 Warszawa</p>
              <br />
              <br />
              <p style={heading}>Biuro Obsługi Klienta</p>
              <p>czynne w godz. 8:00-18:00 (pn-pt)</p>
              <p>tel.: 22 696 72 70</p>
              <br />
              <br />
              <p>Infolinia</p>
              <p style={phoneNumber}>22 696 72 72</p>
              <br />
              <p style={heading}>Formularz kontaktowy</p>
              <div className={'tw-flex tw-flex-col tw-gap-4'}>
                <MyTextInput name='email' placeholder='Email' value={values ? values.email : ''} />
                <MyTextInput name='subject' placeholder='Temat wiadomości' value={values ? values.subject : ''} />
                <MyTextArea
                  name='content'
                  placeholder='Treść wiadomości'
                  rows={4}
                  value={values ? values.content : ''}
                />
              </div>
              <ErrorMessage
                name='error'
                render={() => <label style={{ marginBottom: 10, color: 'red' }}>{errors.error}</label>}
              />

              {isSubmitting ? (
                <Button className='btn-full-width mt-3' disabled={!isValid || isSubmitting} type='submit'>
                  <span className='m-1'>
                    <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                  </span>
                  Wysyłanie…
                </Button>
              ) : (
                <Button className='btn-full-width mt-3' disabled={!isValid || isSubmitting} type='submit'>
                  Wyślij wiadomość
                </Button>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
});
