import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';
import { UserProfileModel } from '../../../types/user';
import UserProfileAddress from '../UserProfileAddress/UserProfileAddress';
import { UserProfileState } from '../UserProfileState';
import * as styles from './styles';

export default observer(
  (props: {
    refreshView: (userProfileState: UserProfileState, refresh: boolean) => void;
    userProfile: UserProfileModel;
  }) => {
    return (
      <div>
        <h2 className={'tw-mb-4'} style={{ color: 'teal', textAlign: 'center' }}>
          Moje Dane
        </h2>
        <Button
          className='mb-2'
          onClick={() => {
            props.refreshView(UserProfileState.EditProfile, false);
          }}
          style={{ width: '100%' }}>
          Edycja profilu
        </Button>
        <Button
          onClick={() => {
            props.refreshView(UserProfileState.ShowPhones, false);
          }}
          style={{ width: '100%' }}>
          Moje Telefony
        </Button>

        <div style={styles.containerBackground}>
          <Row className='mb-3'>
            <Col xs={9}>
              <h6 style={{ paddingTop: '12px', color: 'teal' }}>Główne dane:</h6>
            </Col>
            <Col style={styles.colTextRight as React.CSSProperties}>
              <Image className='greenIcon' src={'/assets/user-solid.svg'} style={styles.imageStyle} />
            </Col>
          </Row>

          <Row className='mt-2'>
            <Col style={styles.colTextLeft as React.CSSProperties}>
              <p>Imię: </p>
            </Col>
            <Col style={styles.colTextRight as React.CSSProperties}>{props.userProfile.firstName}</Col>
          </Row>

          <Row className='mt-2'>
            <Col>
              <p>Nazwisko: </p>
            </Col>
            <Col style={styles.colTextRight as React.CSSProperties}>{props.userProfile.lastName}</Col>
          </Row>

          <Row className='mt-2'>
            <Col>
              <p>Pesel: </p>
            </Col>
            <Col style={styles.colTextRight as React.CSSProperties}>{props.userProfile.pesel}</Col>
          </Row>
        </div>

        {props.userProfile.userAddresses.map((address, i) => {
          return (
            <div key={i} style={styles.containerBackground}>
              <UserProfileAddress userAddress={address} />
            </div>
          );
        })}
      </div>
    );
  },
);
