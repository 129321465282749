import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import { UserPhoneModel } from '../../../types/user';
import UserProfileCreatePhone from '../UserProfileCreatePhone/UserProfileCreatePhone';
import UserProfileDeletePhone from '../UserProfileDeletePhone/UserProfileDeletePhone';
import UserProfileEditPhone from '../UserProfileEditPhone/UserProfileEditPhone';
import UserProfileSetAsMainPhone from '../UserProfileSetAsMainPhone/UserProfileSetAsMainPhone';
import { UserProfileState } from '../UserProfileState';
import UserProfileVerifyPhone from '../UserProfileVerifyPhone/UserProfileVerifyPhone';
import * as styles from './styles';

export default observer(
  (props: {
    refreshView: (userProfileState: UserProfileState, refresh: boolean) => void;
    userProfilePhones: UserPhoneModel[];
  }) => {
    const { modalStore } = useStore();

    const renderStatusSwitch = (param: UserPhoneModel) => {
      if (!param.isActive) {
        return styles.inactive;
      }

      return styles.active;
    };

    const phoneStatus = (param: UserPhoneModel) => {
      if (!param.isActive) {
        return 'Nie Aktywny';
      }

      if (param.isMain) {
        return 'Główny';
      }

      return 'Aktywny';
    };

    return (
      <div>
        <h2 className={'tw-mb-4'} style={{ color: 'teal', textAlign: 'center' }}>
          Moje Telefony
        </h2>
        <Button
          className='mb-2'
          onClick={() => {
            props.refreshView(UserProfileState.ShowProfile, false);
          }}
          style={{ width: '100%' }}>
          Moje Dane
        </Button>
        <Button
          className='mb-2'
          onClick={() => modalStore.openModal(<UserProfileCreatePhone refreshView={props.refreshView} />, 'lg', null)}
          style={{ width: '100%' }}>
          Dodaj nowy numer telefonu
        </Button>

        {props.userProfilePhones.map((phone, i) => {
          return (
            <div key={i} style={_.merge({}, styles.containerBackground as React.CSSProperties)}>
              <Row className='mt-2'>
                <Col xs={5}>
                  <p>Telefon: </p>
                </Col>
                <Col style={styles.colTextRight as React.CSSProperties}>{phone.phone}</Col>
              </Row>

              <Row className='mt-2'>
                <Col xs={5}>
                  <p>Opis: </p>
                </Col>
                <Col style={styles.colTextRight as React.CSSProperties}>{phone.description}</Col>
              </Row>

              <Row className='mt-2'>
                <Col xs={5}>
                  <p>Status: </p>
                </Col>
                <Col style={styles.colTextRight as React.CSSProperties}>
                  <span style={renderStatusSwitch(phone) as React.CSSProperties}>{phoneStatus(phone)}</span>
                </Col>
              </Row>

              <Row>
                {!phone.isMain && phone.isActive ? (
                  <Button
                    className='mb-2'
                    onClick={() =>
                      modalStore.openModal(
                        <UserProfileSetAsMainPhone phone={phone} refreshView={props.refreshView} />,
                        'lg',
                        null,
                      )
                    }
                    style={{ width: '100%' }}>
                    Ustwa jako głowny kontakt
                  </Button>
                ) : null}

                {!phone.isActive ? (
                  <Button
                    className='mb-2'
                    onClick={() =>
                      modalStore.openModal(
                        <UserProfileVerifyPhone phone={phone} refreshView={props.refreshView} />,
                        'lg',
                        null,
                      )
                    }
                    style={{ width: '100%' }}>
                    Aktywuj numer telefonu
                  </Button>
                ) : null}

                {phone.canBeModifiedDeleted ? (
                  <>
                    <Button
                      className='mb-2 mt-2'
                      onClick={() =>
                        modalStore.openModal(
                          <UserProfileEditPhone phone={phone} refreshView={props.refreshView} />,
                          'lg',
                          'Edycja numeru',
                        )
                      }
                      style={{ width: '100%' }}>
                      Edytuj numer
                    </Button>

                    {!phone.isMain ? (
                      <Button
                        className='mb-2'
                        onClick={() =>
                          modalStore.openModal(
                            <UserProfileDeletePhone phone={phone} refreshView={props.refreshView} />,
                            'lg',
                            null,
                          )
                        }
                        style={{ width: '100%' }}>
                        Skasuj numer
                      </Button>
                    ) : null}
                  </>
                ) : null}
              </Row>
            </div>
          );
        })}
      </div>
    );
  },
);
