import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { useStore } from '../../store/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { twMerge } from '../../index';
import { Capacitor } from '@capacitor/core';

export default observer(() => {
  const { modalStore } = useStore();

  const renderHeader = () => {
    if (typeof modalStore.modal.headerTitle === 'function') {
      return modalStore.modal.headerTitle();
    }

    return (
      <Modal.Header className={'tw-padding-[1em] tw-rounded-t-[36px] tw-bg-[#0bc268] tw-text-white'} closeButton>
        <Modal.Title>{modalStore.modal.headerTitle}</Modal.Title>
      </Modal.Header>
    );
  };
  return (
    <Modal
      className={twMerge(
        'tw-py-6 sm:tw-py-0',
        Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios' && 'tw-py-8',
      )}
      onHide={modalStore.closeModal}
      show={modalStore.modal.open}
      size={modalStore.modal.size}>
      {modalStore.modal.headerTitle
        ? renderHeader()
        : modalStore.modal.open && (
            <div className='w-100 d-flex justify-content-end'>
              <span
                onClick={modalStore.closeModal}
                style={{ marginRight: '20px', marginTop: '20px', cursor: 'pointer' } as React.CSSProperties}>
                <FontAwesomeIcon icon={faClose} />
              </span>
            </div>
          )}
      <Modal.Body>{modalStore.modal.body}</Modal.Body>
    </Modal>
  );
});
