import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ReactNode, useEffect } from 'react';
import Header from '../../components/Header/Header';
import CustomerMianNavbar from '../../components/CustomerNavBar/CustomerMainNavBar';
import ProfileMenu from '../../components/ProfileMenu/ProfileMenu';
import { useStore } from '../../store/store';
import { Container } from 'react-bootstrap';
import useHeaderHeight from '../../hooks/useHeaderHeight';

interface ICustomerDashboardProps {
  children: ReactNode;
}
export default observer(({ children }: ICustomerDashboardProps) => {
  const { userStore, cinemaVoucherStore } = useStore();
  const headerHeight = useHeaderHeight();

  useEffect(() => {
    userStore.checkUpdateRequired().then((resp) => {
      if (userStore.hasPesel == null) {
        userStore.checkHasPesel();
      }
      if (resp) {
        userStore.logout();
      }
    });
  }, []);

  useEffect(() => {
    if (cinemaVoucherStore.companies.length === 0) {
      cinemaVoucherStore.getAvailableCinemaVoucherTypes().then((resp) => {
        cinemaVoucherStore.setCompanies(resp);
      });
    }
  }, []);
  return (
    <div style={{ backgroundColor: ' #ececec ', minHeight: 'calc(100vh - 5em)' }}>
      <Container>
        <CustomerMianNavbar />
        <ProfileMenu />
        <Header />
        <div style={{ height: `${headerHeight}px` }}>{/* Empty div for header height */}</div>

        <main id={'customer-main-container'} style={{ paddingTop: '15px', paddingBottom: '15px' }}>
          {children}
        </main>
      </Container>
    </div>
  );
});
