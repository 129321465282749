import { PhoneModel } from '../../types/phoneSubscription';
import Config from '../../Config';
import MyCircleCheckIcon from '../../components/Icons/MyCircleCheckIcon';

interface IPhoneTileProps {
  phone: PhoneModel;
  onClick: (phoneId: number) => void;
  selectedPhoneId?: number;
}
export const PhoneTile = (props: IPhoneTileProps) => (
  <div
    className='tw-relative tw-h-fit  tw-w-full tw-cursor-pointer tw-overflow-hidden tw-rounded-lg tw-border tw-bg-white'
    onClick={() => props.onClick(props.phone.id)}>
    {props.selectedPhoneId === props.phone.id && (
      <div
        className={'tw-absolute tw-right-1 tw-top-1 tw-z-30 tw-rounded-full tw-bg-nau-green-light tw-p-0.5 tw-shadow'}>
        <MyCircleCheckIcon className={'tw-h-5 tw-w-5   tw-text-white'} />
      </div>
    )}
    <div className={'p-2'}>
      <div
        className=' tw-min-h-0'
        style={{
          paddingBottom: '100%', // Ensures the div is square
          position: 'relative',
        }}>
        <img
          alt={'phone_thumbnail'}
          className='tw-absolute tw-inset-0 tw-h-full tw-w-full tw-rounded-md tw-object-contain'
          src={
            props.phone.imageId
              ? (Config.appUrl + '/File/GetPhoneImage?fileId=').replace('//File', '/File') + props.phone.imageId
              : '/assets/nauMobilePromo/otherPhone.svg'
          }
        />
      </div>
    </div>

    <div className='tw-p-2'>
      <h3 className='tw-min-h-[40px] tw-text-center tw-text-sm tw-text-nau-sea-green '>{props.phone.modelName}</h3>
    </div>

    {!!props.phone.price && (
      <div className='tw-mx-2  tw-mt-2 tw-text-sm tw-text-nau-sea-green'>
        <div>
          <div className={' tw-font-bold'}>
            <div className={'tw-relative tw-inline-block tw-text-4xl tw-leading-none sm:tw-text-6xl'}>
              <span>{props.phone.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</span>
              <div
                className={
                  'tw-absolute tw-inset-x-0 tw-top-1/2 tw-h-[6px]  tw-w-full tw--translate-y-1/2 tw--rotate-[13deg]  tw-rounded-full tw-bg-[#f90707]'
                }></div>
            </div>
            <span className={'tw-text-xl '}> zł*</span>
          </div>
        </div>
        <div>
          <div className={' tw-font-bold'}>
            <div className={'tw-relative tw-inline-block tw-text-4xl tw-leading-none tw-text-[#f90707] sm:tw-text-6xl'}>
              <span>- 20%</span>
            </div>
          </div>
        </div>
        <div>
          <div className={'tw-mt-2'}>Cena telefonu</div>
          <div className={'tw-mb-2'}>po rabatach**</div>
          <div className={' tw-font-bold'}>
            <span className={'tw-text-4xl sm:tw-text-6xl'}>
              {' '}
              {(props.phone.price * 0.8).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
            </span>
            <span className={'tw-text-xl '}> zł</span>
          </div>
        </div>
      </div>
    )}
  </div>
);
