import { ErrorMessage, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import MyTextInput from '../FormControls/MyTextInput/MyTextInput';
import { useStore } from '../../store/store';
import MyFooterContact from '../MyFooterContact/MyFooterContact';
import { PathRoute } from '../../constants/pathRoute/Route';
import * as styles from './styles';
import LogoHeader from '../LogoHeader/LogoHeader';
import UpdateRequiredBar from '../UpdateRequiredBar/UpdateRequiredBar';
import TechnicalBreak from '../../containers/TechnicalBreak/TechnicalBreak';
import useQuery from '../../hooks/useQuery';
import Cookies from 'js-cookie';
import MyCheckbox from '../FormControls/MyCheckbox/MyCheckbox';
import { CASHBACK_POLICY_DATA, INSTITUTION_TOKEN, PROMO_CODE_KEY } from '../../constants/sessionStorageKeys';
import { UserFormValues } from '../../types/user';
import PromoCodeBox from '../PromoCode/PromoCodeBox';
import { LoginBenefitsSection } from './LoginBenefitsSection';
import CashbackPromoBox from '../PromoCode/CashbackPromoBox';
import { ICashbackSopPolicyGroupDto } from '../../types/cashback';
import InstitutionTokenBox from '../PromoCode/InstitutionCodeBox';
import { getPathOrRedirect, getPathWithRedirectIfExists } from '../../utils/linkUtils';

export default observer(() => {
  const { userStore } = useStore();
  const query = useQuery();
  const referralCode = query.get('ref');

  if (referralCode) {
    Cookies.set('referralCode', referralCode);
  }

  if (userStore.isLoggedIn) {
    const redirectPath = getPathOrRedirect(PathRoute.PUSTY);
    return <Redirect to={redirectPath} />;
  }

  return (
    <>
      {userStore.technicalBreak && <TechnicalBreak />}
      {userStore.updateRequired && <UpdateRequiredBar />}
      {!userStore.technicalBreak && !userStore.updateRequired && (
        <Formik
          initialValues={{ email: '', password: '', rememberMe: false, error: null }}
          onSubmit={({ email, password, rememberMe }, { setErrors, setSubmitting }) => {
            const cashbackPolicyData = sessionStorage.getItem(CASHBACK_POLICY_DATA);
            const cashbackPolicyDataObject: ICashbackSopPolicyGroupDto | undefined = cashbackPolicyData
              ? JSON.parse(cashbackPolicyData)
              : undefined;

            const loginFormValues: UserFormValues = {
              email,
              password,
              rememberMe,
              promoCode: sessionStorage.getItem(PROMO_CODE_KEY) ?? undefined,
              cashbackSopPolicyDto: cashbackPolicyDataObject,
              institutionToken: sessionStorage.getItem(INSTITUTION_TOKEN) ?? undefined,
            };
            userStore.login(loginFormValues).catch((error) => {
              if (Array.isArray(error)) {
                let errorMsg = '';
                error.forEach((element: string, index: number) => {
                  if (index > 0) errorMsg += '<br/>';
                  errorMsg += element;
                  setErrors({ error: errorMsg });
                });
                setSubmitting(false);
              } else {
                setErrors({ error: 'Nieprawidłowy e-mail lub hasło' });
              }
            });
          }}>
          {({ handleSubmit, isSubmitting, errors }) => (
            <Form
              autoComplete='off'
              className='tw-mx-auto tw-max-w-2xl tw-px-2'
              onSubmit={handleSubmit}
              style={{ paddingBottom: '80px', marginTop: '2em' } as React.CSSProperties}>
              <div style={styles.containerRegister as React.CSSProperties}>
                <LogoHeader />
              </div>
              <div className={'tw-text-4xl tw-font-light'} style={styles.mainText as React.CSSProperties}>
                Witaj w Klubie NAU!
              </div>
              <div className={'tw-flex tw-flex-col tw-gap-2'}>
                <PromoCodeBox />
                <CashbackPromoBox />
                <InstitutionTokenBox />
              </div>

              <div className='tw-mb-4 tw-font-normal tw-text-nau-green-dark'>Zaloguj się</div>
              <div className={'tw-flex tw-flex-col tw-gap-4 tw-font-normal'}>
                <MyTextInput name='email' placeholder='Adres E-mail' type={'text'} />
                <MyTextInput name='password' placeholder='Hasło' type={'password'} />
              </div>

              <div className={'tw-mt-4 tw-flex tw-items-center tw-justify-between'}>
                <MyCheckbox
                  label='Pamiętaj mnie'
                  name={`rememberMe`}
                  style={styles.loginText}
                  type='checkbox'
                  variant={'big'}
                />
                <div className=''>
                  <Link
                    className={'tw-text-sm tw-text-nau-green-light hover:tw-text-nau-green-light'}
                    to={getPathWithRedirectIfExists(PathRoute.REMEMBER_PASSWORD)}>
                    Odzyskaj hasło
                  </Link>
                </div>
              </div>

              <ErrorMessage
                name='error'
                render={() => (
                  <label dangerouslySetInnerHTML={{ __html: errors.error ?? '' }} style={styles.labelError}></label>
                )}
              />
              {isSubmitting ? (
                <Button className='btn-full-width mt-3' type='submit'>
                  <span className='m-1'>
                    <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                  </span>
                  Logowanie…
                </Button>
              ) : (
                <Button className='btn-full-width mt-3' disabled={userStore.updateRequired} type='submit'>
                  Logowanie
                </Button>
              )}
              <div className='tw-flex tw-justify-end  tw-pt-6'>
                {!userStore.updateRequired && (
                  <Link
                    className={'tw-text-sm tw-text-nau-green-dark hover:tw-text-nau-green-dark'}
                    to={getPathWithRedirectIfExists(PathRoute.REGISTER)}>
                    Nie posiadasz konta? <span className={'tw-font-semibold'}>Załóż</span>
                  </Link>
                )}
              </div>

              <div className={'tw-my-6 tw-h-[2px] tw-w-full tw-bg-nau-green-dark'}></div>

              <LoginBenefitsSection />
              <MyFooterContact />
            </Form>
          )}
        </Formik>
      )}
    </>
  );
});
