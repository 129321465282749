import React, { useState } from 'react';
import { useStore } from '../../store/store';
import { useSwrAgent } from '../../api/useSwrAgent';
import { Form, Formik, FormikHelpers } from 'formik';
import MyButton from '../../components/Buttons/MyButton/MyButton';
import { PhoneModel, PhoneSubscriptionLead, StepType } from '../../types/phoneSubscription';
import * as Yup from 'yup';
import { usePhoneSubscriptionLeadStore } from '../../store/PhoneSubscription/phoneSubscriptionStore';
import { AnotherPhoneFormModal } from './AnotherPhoneFormModal';
import agent, { requests } from '../../api/agent';
import LoadingComponent from '../../components/Loadings/LoadingComponent/LoadingComponent';
import { toast } from 'react-toastify';
import FileInput from './FileInput';
import FileHelper from '../../helpers/FileHelper';
import { PathRoute } from '../../constants/pathRoute/Route';
import MyCheckbox from '../../components/FormControls/MyCheckbox/MyCheckbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';

const validationSchema = Yup.object().shape({
  userId: Yup.number().required('Pole "Identyfikator użytkownika" jest wymagane.'),
  currentStepId: Yup.number().required('Pole "Obecny krok" jest wymagane.'),
});

const otherPhoneModel: PhoneModel = {
  id: 0,
  modelName: 'Interesuje mnie inny model',
};

interface PromoFormProps {
  afterSave?: () => void;
}

export const PromoForm = (props: PromoFormProps) => {
  const { data: phoneModels, isLoading: arePhoneModelsLoading } = useSwrAgent().NauMobile.GetAvailablePhonesModel();
  const { lead, updateLead, setStep } = usePhoneSubscriptionLeadStore();
  const { modalStore } = useStore();
  const selectedPhone = phoneModels?.find((phone) => phone.id === lead.selectedPhoneId);
  const phoneModelsToUse = [...(phoneModels ?? []), otherPhoneModel];

  const getPercentageDiscount = (values: PhoneSubscriptionLead) => {
    const hasBrOrRrPolicy = values.hasBrOrRrPolicy;
    const lifeInsuranceOwnership = values.lifeInsuranceOwnership;
    let percentageDiscount = 0;
    if (hasBrOrRrPolicy) {
      percentageDiscount += 10;
    }
    if (lifeInsuranceOwnership) {
      percentageDiscount += 10;
    }
    return percentageDiscount;
  };

  const getPhonePriceAfterDiscount = (values: PhoneSubscriptionLead) => {
    const phoneValue = (selectedPhone?.price ?? 0) * ((100 - getPercentageDiscount(values)) / 100);
    return Math.round((phoneValue * 100) / 100);
  };

  return (
    <div className='tw-w-full tw-rounded-b-lg tw-bg-white  tw-px-2 tw-py-6 tw-shadow-md sm:tw-px-6 '>
      <LoadingComponent content='Ładowanie...' visible={arePhoneModelsLoading} />

      <Formik
        enableReinitialize={true}
        initialValues={lead}
        onSubmit={async (values, { setSubmitting, setFieldValue }: FormikHelpers<PhoneSubscriptionLead>) => {
          try {
            await validationSchema.validate(values);
          } catch (error: any) {
            toast.error(error.message as string);
            return;
          }
          if (values.selectedPhoneId === null || values.selectedPhoneId === undefined) {
            toast.info('Proszę wybrać telefon');
            return;
          }

          if (values.selectedPhoneId) {
            agent.NauMobile.updateLeadFormModel({
              ...values,
              currentStepId: StepType.SelectDiscounts,
            }).then(() => {
              props.afterSave?.();
            });
          } else {
            modalStore.openModal(<AnotherPhoneFormModal />, 'lg', null);
          }
        }}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className='tw-w-full'>
            {lead.selectedPhoneId != 0 && (
              <>
                <div className={'tw-mb-4 tw-w-fit tw-cursor-pointer '} onClick={() => setStep(StepType.SelectPhone)}>
                  <FontAwesomeIcon color='#626d80' icon={faCaretLeft} size='2x' />
                </div>
                <div className='tw-mb-2  tw-text-center tw-text-2xl tw-font-semibold tw-text-nau-green-light'>
                  RABATY SPECJALNIE DLA CIEBIE
                </div>
                <div className=' tw-mb-2 tw-text-center tw-text-2xl tw-font-bold tw-text-nau-sea-green'>
                  NAWET DO 20%
                </div>
                <div className={'tw-flex tw-items-center tw-justify-between tw-gap-4 tw-py-4'}>
                  <div>-10%</div>
                  <MyCheckbox
                    label='Moja szkoła jest ubezpieczona za pośrednictwem NAU w grupowym ubezpieczeniu na życie*'
                    labelClassName={'tw-text-nau-sea-green'}
                    name={`lifeInsuranceOwnership`}
                    type='checkbox'
                    onChange={(e) => updateLead({ lifeInsuranceOwnership: e.target.checked })}
                    value={values.lifeInsuranceOwnership}
                    variant={'big'}
                  />
                </div>
                <div className={'tw-flex tw-flex-col tw-gap-4 tw-py-2'}>
                  <FileInput
                    label={'Dołącz skan polisy'}
                    onFileChange={async (file: File | null) => {
                      if (!file) return;

                      const formData = new FormData();
                      formData.append('file', file);

                      return requests.post<number>('/NauMobile/SaveNauMobileLeadFile', formData).then((response) => {
                        toast.success('Plik został zapisany w formularzu');
                        setFieldValue('policyLifeScanFileId', response);
                      });
                    }}
                  />
                  <FileInput
                    label={'Dołącz potwierdzenie ostatniej wpłaty'}
                    onFileChange={async (file: File | null) => {
                      if (!file) return;

                      const formData = new FormData();
                      formData.append('file', file);

                      return requests.post<number>('/NauMobile/SaveNauMobileLeadFile', formData).then((response) => {
                        toast.success('Plik został zapisany w formularzu');
                        setFieldValue('lastPaymentPolicyLifeFileId', response);
                      });
                    }}
                  />
                </div>
                <div className={' tw-bg-white tw-p-4 tw-text-center tw-text-nau-green-light'}>
                  Powyższe dokumenty możesz również nadesłać na poniższy adres mailowy:
                  <div className={'tw-font-semibold'}>benefity@nau.pl</div>
                </div>
                <div className={'tw-flex tw-items-center tw-justify-between tw-gap-4 tw-py-4'}>
                  <div>-10%</div>
                  <MyCheckbox
                    label='Moja szkoła jest ubezpieczona za pośrednictwem NAU w grupowym ubezpieczeniu w ramach Programu Bezpieczna Rodzina lub Rodzina Razem*'
                    labelClassName={'tw-text-nau-sea-green'}
                    name={`hasBrOrRrPolicy`}
                    type='checkbox'
                    onChange={(e) => updateLead({ hasBrOrRrPolicy: e.target.checked })}
                    value={values.hasBrOrRrPolicy}
                    variant={'big'}
                  />
                </div>
                <div className={'tw-text-nau-sea-green'}>
                  * Warunkiem skorzystania z Promocji jest utrzymanie przez okres 12 miesięcy składki na poziomie 70% z
                  dnia przystąpienia (szczegóły w{' '}
                  <span
                    onClick={() =>
                      FileHelper.downloadFile(
                        PathRoute.PHONE_SUBSCRIPTION_PROMO_TERMS_PDF,
                        'Regulamin-Promocji-NAU-Laczy-Oswiate.pdf',
                      )
                    }>
                    Regulaminie Promocji
                  </span>
                  )
                </div>
              </>
            )}
            {selectedPhone && selectedPhone.price && (
              <div className='tw-mx-2 tw-mt-2 tw-flex tw-flex-col tw-items-center tw-text-sm tw-text-nau-sea-green'>
                <div className=' tw-mb-2 tw-text-center tw-text-2xl tw-font-bold tw-text-nau-sea-green'>
                  PODSUMOWANIE
                </div>

                <div>
                  <div className={' tw-font-bold'}>
                    <div className={'tw-relative tw-inline-block tw-text-4xl tw-leading-none sm:tw-text-6xl'}>
                      <span>{selectedPhone.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</span>
                      {getPhonePriceAfterDiscount(lead) != selectedPhone.price && (
                        <div
                          className={
                            'tw-absolute tw-inset-x-0 tw-top-1/2 tw-h-[6px]  tw-w-full tw--translate-y-1/2 tw--rotate-[13deg]  tw-rounded-full tw-bg-[#f90707]'
                          }></div>
                      )}
                    </div>
                    <span className={'tw-text-xl '}> zł</span>
                  </div>
                </div>
                {getPhonePriceAfterDiscount(lead) != selectedPhone.price && (
                  <div>
                    <div className={'tw-mt-2'}>Cena telefonu</div>
                    <div className={'tw-mb-2'}>po rabatach</div>
                    <div className={' tw-font-bold'}>
                      <span className={'tw-text-4xl sm:tw-text-6xl'}>
                        {' '}
                        {getPhonePriceAfterDiscount(lead)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                      </span>
                      <span className={'tw-text-xl '}> zł</span>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className='tw-mt-8 tw-flex tw-flex-col tw-gap-4'>
              <MyButton
                className='tw-w-full'
                disabled={isSubmitting}
                isLoading={isSubmitting}
                type='submit'
                variant={'greenDark'}>
                Dalej
              </MyButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
