import { IIconComponentProps } from '../../types/icons';
import { twMerge } from 'tailwind-merge';

export default function MyLinkIcon(props: IIconComponentProps) {
  return (
    <svg
      className={twMerge(props.className)}
      data-name='Group 1906'
      height='20.963'
      id='Group_1906'
      viewBox='0 0 20.963 20.963'
      width='20.963'
      xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <clipPath id='clip-path'>
          <rect data-name='Rectangle 9236' fill='#868d96' height='20.963' id='Rectangle_9236' width='20.963' />
        </clipPath>
      </defs>
      <g clipPath='url(#clip-path)' data-name='Group 1905' id='Group_1905'>
        <path
          d='M10.12,1.881,7.875,4.127A1.258,1.258,0,0,0,9.654,5.905l2.255-2.255a3.812,3.812,0,1,1,5.426,5.357L15.045,11.3a1.258,1.258,0,1,0,1.779,1.779l2.289-2.289A6.328,6.328,0,1,0,10.12,1.881M1.824,19.1a6.335,6.335,0,0,0,8.961.013l2.289-2.289A1.258,1.258,0,1,0,11.3,15.046L9.007,17.335A3.812,3.812,0,1,1,3.65,11.909L5.9,9.655A1.258,1.258,0,0,0,4.126,7.876L1.88,10.121A6.367,6.367,0,0,0,1.824,19.1'
          data-name='Path 4999'
          fill='#868d96'
          id='Path_4999'
          transform='translate(0 -0.001)'
        />
        <path
          d='M177.948,170.421l-7.526,7.526a1.3,1.3,0,0,0,1.779,1.779l7.526-7.526a1.3,1.3,0,0,0-1.779-1.779'
          data-name='Path 5000'
          fill='#868d96'
          id='Path_5000'
          transform='translate(-164.599 -164.598)'
        />
      </g>
    </svg>
  );
}
