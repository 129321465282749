import React from 'react';
import { useStore } from '../../../store/store';
import { Link } from 'react-router-dom';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { Button } from 'react-bootstrap';
import { usePhoneSubscriptionLeadStore } from '../../../store/PhoneSubscription/phoneSubscriptionStore';
import { PhoneOfferType } from '../../../types/phoneSubscription';

export const CashFormSummaryPage = () => {
  const { modalStore } = useStore();
  const { lead } = usePhoneSubscriptionLeadStore();

  return (
    <div className='tw-w-full tw-rounded-b-lg tw-bg-white tw-px-2 tw-py-6 tw-shadow-md sm:tw-px-6'>
      <div className={'tw-flex tw-flex-col tw-gap-10 '}>
        <div className={'tw-text-center tw-text-2xl tw-font-semibold tw-text-nau-sea-green'}>Podsumowanie</div>
        {lead.offerTypeId == PhoneOfferType.Phone ? (
          <>
            <div className={'tw-text-center tw-text-xl tw-text-nau-sea-green'}>
              Po weryfikacji Twoich danych, otrzymasz fakturę za telefon.
              <br />
              <br />
              Po opłaceniu faktury prześlemy telefon.
              <br />
              <br />
              Dziękujemy za skorzystanie z naszej oferty.
            </div>
          </>
        ) : (
          <div className={'tw-text-center tw-text-xl tw-text-nau-sea-green'}>
            Po weryfikacji Twoich danych zostanie zawarta umowa na usługę SIM NAU.
            <br />
            <br />
            Wraz z umową otrzymasz fakturę za telefon. Telefon prześlemy kurierem po opłaceniu faktury.
            <br />
            <br />
            Dziękujemy za skorzystanie z naszej oferty.
          </div>
        )}

        <div className='tw-px-4'>
          <Link className={'tw-w-full'} to={PathRoute.PUSTY}>
            <Button
              className={'tw-w-full'}
              onClick={() => {
                modalStore.closeModal();
              }}
              variant='primary'>
              Wróć do strony głównej
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
