import { useSwrAgent } from '../api/useSwrAgent';
import { useStore } from '../store/store';
import { Capacitor } from '@capacitor/core';

function useHeaderHeight() {
  const { data: userMarketingData } = useSwrAgent().Users.GetUserMarketingData();
  const { userStore } = useStore();

  const getHeaderHeight = () => {
    let headerHeight = 75;
    if (!userStore.isEmailConfirmed) {
      headerHeight += 30;
    }
    if (userMarketingData?.isTeacherPromo) {
      headerHeight += 30;
    }
    if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios') {
      headerHeight += 40;
    }

    return headerHeight;
  };

  return getHeaderHeight();
}

export default useHeaderHeight;
