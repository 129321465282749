import CustomerDashboard from '../CustomerDashboard/CustomerDashboard';
import UserPersonalDataLayout from '../../components/UserPersonalData/UserPersonalDataLayout';
import React from 'react';
import { PhoneSubscriptionMainPage } from './PhoneSubscriptionMainPage';
import { BenefitApprovalsLayout } from '../../components/ApprovalsForm/BenefitApprovalsLayout';
import { Benefit } from '../../enums/benefits.enums';

export const PhoneSubscriptionLayout = () => {
  return (
    <>
      <CustomerDashboard>
        <BenefitApprovalsLayout
          benefitId={Benefit.NauMobile}
          allRequired={false}
          wrapperClassName={'tw-mx-auto tw-max-w-2xl tw-bg-white tw-px-2 tw-py-6 sm:tw-px-6 tw-rounded-lg'}>
          <PhoneSubscriptionMainPage />
        </BenefitApprovalsLayout>
      </CustomerDashboard>
    </>
  );
};
