import React from 'react';
import { PhoneModel } from '../../types/phoneSubscription';

interface IPhoneDescriptionProps {
  phone: PhoneModel;
  showDescription?: boolean;
  showParameters: boolean;
}
export const PhoneDescription = (props: IPhoneDescriptionProps) => {
  return (
    <div>
      {props.phone.description && props.showDescription && (
        <div
          className={'tw-mt-4 tw-max-w-[500px] tw-text-left tw-text-sm tw-text-nau-sea-green'}
          dangerouslySetInnerHTML={{ __html: props.phone.description }}></div>
      )}
      {props.showParameters && (
        <>
          <div className='tw-mb-4 tw-mt-8 tw-text-center tw-text-xl tw-font-semibold tw-text-nau-green-dark'>
            Kluczowe parametry
          </div>
          <div>
            <div className={'tw-text-nau-sea-green'}>
              {props.phone.display && (
                <div>
                  <strong>Wyświetlacz:</strong>
                  <div>{props.phone.display}</div>
                </div>
              )}
              {props.phone.memory && (
                <div>
                  <strong>Pamięć:</strong>
                  <div>{props.phone.memory}</div>
                </div>
              )}
              {props.phone.camera && (
                <div>
                  <strong>Aparat:</strong>
                  <div>{props.phone.camera}</div>
                </div>
              )}
              {props.phone.operatingSystem && (
                <div>
                  <strong>System operacyjny:</strong>
                  <div>{props.phone.operatingSystem}</div>
                </div>
              )}
              {props.phone.processor && (
                <div>
                  <strong>Procesor:</strong>
                  <div>{props.phone.processor}</div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
