import React from 'react';
import { useStore } from '../../../store/store';
import { Link } from 'react-router-dom';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { Button } from 'react-bootstrap';
import { usePhoneSubscriptionLeadStore } from '../../../store/PhoneSubscription/phoneSubscriptionStore';
import { PhoneOfferType } from '../../../types/phoneSubscription';

export const InstallmentThankYouPage = () => {
  const { modalStore } = useStore();
  const { lead } = usePhoneSubscriptionLeadStore();
  return (
    <div className='tw-w-full tw-rounded-b-lg tw-bg-white tw-px-2 tw-py-6 tw-shadow-md sm:tw-px-6'>
      <div className={'tw-flex tw-flex-col tw-gap-10 '}>
        <div className={'tw-text-center tw-text-2xl tw-font-semibold tw-text-nau-sea-green'}>Podsumowanie</div>
        {lead.offerTypeId == PhoneOfferType.Phone ? (
          <div className={'tw-text-center tw-text-xl tw-text-nau-sea-green'}>
            Po weryfikacji Twoich danych zostanie zawarta umowa ratalna na telefon.
            <br />
            <br />
            Telefon otrzymasz kurierem po uregulowaniu opłaty na start (jeśli była wybrana) i podpisaniu wyżej
            wskazanych umów.
            <br />
            <br />
            Dziękujemy za skorzystanie z naszej oferty.
          </div>
        ) : (
          <div className={'tw-text-center tw-text-xl tw-text-nau-sea-green'}>
            Po weryfikacji Twoich danych zostanie zawarta umowa na usługę SIM NAU i umowa ratalna na telefon.
            <br />
            <br />
            Telefon otrzymasz kurierem po uregulowaniu opłat i podpisaniu wyżej wskazanych umów.
            <br />
            <br />
            Dziękujemy za skorzystanie z naszej oferty.
          </div>
        )}
        <div className='tw-px-4'>
          <Link className={'tw-w-full'} to={PathRoute.PUSTY}>
            <Button
              className={'tw-w-full'}
              onClick={() => {
                modalStore.closeModal();
              }}
              variant='primary'>
              Wróć do strony głównej
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
