export default class CalculationHelpers {
  static pmt = (rate: number, nper: number, pv: number): number => {
    if (rate === 0) {
      return -pv / nper;
    }
    const x = Math.pow(1 + rate, nper);
    const payment: number = -((pv * x * rate) / (x - 1) + 0 / (x - 1));
    return payment;
  };
  static calculateMonthlyInterest = (principal: number, annualInterestRate: number): number => {
    return principal * (annualInterestRate / 12);
  };
  static calculateCommission = (principal: number, commissionRate: number, months: number): number => {
    return (principal * commissionRate) / months;
  };
  static calculateMonthlyInstllment = (
    annualPercent: number,
    commission: number,
    numberOfMonths: number,
    price: number,
  ): number => {
    const monthlyPayment = CalculationHelpers.pmt(annualPercent / 12, -numberOfMonths, price);
    const monthlyInterest = CalculationHelpers.calculateMonthlyInterest(price, annualPercent);
    const commissionAmount = CalculationHelpers.calculateCommission(price, commission, numberOfMonths);
    return monthlyPayment + monthlyInterest + commissionAmount;
  };
}
